<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员管理</el-breadcrumb-item>
            <el-breadcrumb-item>会员列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{account}}的余额流水</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="2" class="add-btn-container">
          <el-button type="text" @click="back">返回会员列表</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="收入/支出">
        <el-radio-group v-model="direction">
          <el-radio :label=-1>全部</el-radio>
          <el-radio :label=1>支出</el-radio>
          <el-radio :label=0>收入</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-input v-model="OrderNO" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table :data="listData" border="" style="width: 100%">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>

        <el-table-column prop="orderNO" label="订单号" align="center"></el-table-column>
        <el-table-column prop="money" label="金额" align="center">
          <template slot-scope="scope">{{scope.row.direction==0?'-'+scope.row.money:'+'+scope.row.money}}</template>
        </el-table-column>
        <el-table-column prop="describe" label="描述" align="center"></el-table-column>

        <el-table-column label="性质" align="center">

          <template slot-scope="scope">{{ ["消费", "佣金", "赠送",'充值','扣除','退款'][scope.row.nature] }}</template>
        </el-table-column>
        <el-table-column prop="addDate" label="添加日期" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background="" layout="prev, pager, next" :page-size="10" :current-page="pageIndex" :total="pageTotal" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getBalanceListRequest } from '@/api/wxuser'
export default {
  name: 'Balance',
  data() {
    return {
      account: '',
      UserAccount: '',
      direction: -1,
      OrderNO: '',
      directiontext: '方向',
      listData: [],
      pageIndex: 1,
      pageTotal: 0,
      id: 0,
    }
  },
  created() {
    this.id = this.$route.query['id']
    this.getList()
  },
  methods: {
    getList() {
      getBalanceListRequest({
        id: this.id,
        direction: this.direction,
        OrderNO: this.OrderNO,
        pageNum: this.pageIndex,
        pageSize: 10,
      }).then((res) => {
        let data = res.data
        this.listData = data.data
        this.pageTotal = data.count
      })
    },
    back() {
      this.$router.go(-1)
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    handleCommand(command) {
      this.direction = command
      this.directiontext = command == 0 ? '支出' : '收入'
    },
  },
}
</script>

<style lang="less" scoped>
</style>
